<template>
  <div class="coninter">
    <div
      v-for="item in mean_list"
      :key="item.meta.active"
      @click="
        sidekuai_active = item.meta.active;
        $router.push({ name: item.name });
      "
      :class="
        sidekuai_active == item.meta.active ? 'sidekuai_active' : 'sidekuai'
      "
    >
      <div class="skimg">
        <img
          v-if="sidekuai_active == item.meta.active"
          :src="item.meta.icon2"
          alt=""
        />
        <img
          v-if="sidekuai_active != item.meta.active"
          :src="item.meta.icon"
          alt=""
        />
      </div>
      <div class="sktext">{{ item.meta.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sidekuai_active: this.$route.meta.parent_active,
    };
  },

  computed: {
    // 获取当前路由的子路由
    mean_list() {
      var routes = {
        children: this.$router.options.routes,
      };

      routes = routes.children.find((e) => e.name == "energyIndex");
      // console.log(routes,'----------------');
      // console.log(routes.children)
      let list = routes.children.map((item) => {
        for (let i = 0; i < this.$store.state.role.length; i++) {
          if (!item.meta || !item.meta.role) {
            return item;
          }
          if (item.meta.role.indexOf(this.$store.state.role[i]) != -1) {
            return item;
          }
        }
      });
      // console.log(list.filter(d=>d),'----------------');
      return list.filter((d) => d);
    },
  },

  mounted() {},

  methods: {
    change_url() {},
  },
};
</script>
<style lang="scss" scoped>
.coninter {
  width: 120px;
  height: 100vh;
  background: linear-gradient(0deg, #3086fb, #56aeff);
  padding-top: 173px;
}
.sidekuai {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 70px;
  margin-bottom: 40px;
  cursor: pointer;
  .sktext {
    color: #ffffff;
    font-size: 16px;
  }
  .skimg {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
  }
  .skimg > img {
    width: 100%;
    height: 100%;
  }
}
.sidekuai_active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  /* padding-left: 25px; */
  width: 93px;
  padding-right: 14px;
  margin-bottom: 40px;
  margin-left: 12px;
  cursor: pointer;
  background-color: #ffffff;
  border-right: 4px solid #3086fb;
  .sktext {
    color: #3086fb;
    font-size: 16px;
  }
  .skimg {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
    // padding-left: 3px;
  }
  .skimg > img {
    width: 100%;
    height: 100%;
  }
}
</style>
