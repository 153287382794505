<template>
  <div class="cointener">
    <div class="ininside">
      <energy-inside/>
    </div>
    <div class="inright">
      <div class="innav">
        <energy-nav/>
      </div>

      <div class="inconent">
        <router-view></router-view>
      </div>
    </div>

  </div>
</template>
<script>
import energyInside from "../EngeryEvaluation/components/energyInside.vue"
import energyNav from "../EngeryEvaluation/components/energyNav.vue"

export default {
  components: {energyInside, energyNav},
  data() {
    return {
      active: 0,
    }
  },

  methods: {
    changeUrl() {

    },
  }
}
</script>
<style scoped>
.cointener {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  background-color: #fafafa;
  background-size:100% 100% ;
}

.ininside {
  padding-right: 5px;
  position: fixed;
  z-index: 999;
}

.innav {
  margin: 0 10px;
  /* width: 99% */
}

.inright {
  width: 100%;
  margin-left: 120px;
}

.inconent {
  padding:10px;
  overflow-y: scroll;
     height: 85vh;
   /* height: 90vh; */
}
.inconent::-webkit-scrollbar{
  display: none;
}
</style>
