<template>
  <div class="connav">
      <div class="cointer">
     <div class="titlena">
      江苏省民用建筑能效测评标识管理系统
    </div>
    <div class="navright">
      <div class="navcen">
        <i class="el-icon-s-custom"></i>
        <span v-if="$store.state.user.name && $store.state.user.name!=''">{{$store.state.user.name}}</span>
        <span v-else>{{$store.state.user.username}}</span>
      </div>
      <div @click="loginOut" class="navsec">
        <i class="el-icon-switch-button"></i>
        退出
      </div>
    </div>
  </div>
    <div class="navtab">
      <div class="navleft" v-if="mean_list.length>1">
        <div v-for="(item,index) in mean_list" :key="item.name" @click="changeTab(index,item)">
          <div  v-if="!item.meta.hidden && !(item.path=='enchildmanage' && $store.state.user.fid)" :class="navleft_active==item.meta.active?'nlfir_active':'nlfir'"  >
            {{item.meta.name}}
          </div>
        </div>
      </div>
    </div>
   

  </div>
</template>
<script>
export default {

  computed: {
    // 获取当前路由的子路由
    mean_list(){
      var routes = {
        children: this.$router.options.routes
      };

      // console.log(routes);

      var route = this.$route.matched;
      // console.log(route)

      for(var i=0; i<route.length-1; i++){
        routes = routes.children.find((e) => (e.name == route[i].name));
      }
      // routes = routes.children.find((e) => (e.meta.hidden))
      routes.children = routes.children.map(item =>{
        if (!item.meta.hidden){
          return item;
        }
      }).filter(d => d);
      // console.log(routes.children)
      return routes.children
    },
    navleft_active(){
      return this.$route.meta.active
    }
  },

  data() {
    return {

    };
  },
  methods:{

    loginOut(){
      this.$store.commit('login_out');
    },

    changeTab(index,item){
      this.navleft_active = item.meta.active;
      this.$router.push({
        path: item.path,
      })
    }
  }
}
</script>
<style scoped>
.navtab{
  background-color: #ffffff;
}
.titlena{
  height: 60px;
  line-height: 60px;
  margin-left: 10px;
  font-size: 22px;
  color: #3086FB;
  /* background-color: #ffffff; */
}
.cointer {
  width: 100%;
  height: 60px;
  background-color: #fafafa;
  box-shadow: -1px 1px 20px 0px rgba(59, 55, 85, 0.03);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navleft {
  display: flex;
  flex-direction: row;
}

.navright {
  display: flex;
  flex-direction: row;
}

.navleft .nlfir {
  width: 100px;
  text-align: center;
  /* border-bottom: 4px solid #479DFD; */
  padding: 18px 0;
  margin-left: 22px;
  margin-right: 58px;
  cursor: pointer;
}

.navleft .nlfir_active {
  width: 100px;
  text-align: center;
  border-bottom: 4px solid #479DFD;
  padding: 18px 0;
  margin-left: 22px;
  margin-right: 58px;
  cursor: pointer;
}

.navleft .nlsec {
  width: 70px;
  text-align: center;
  /* border-bottom: 4px solid #479DFD; */
  padding: 18px 0;
  margin-left: 22px;
  cursor: pointer;
}

.navleft .nlsec_active {
  width: 70px;
  text-align: center;
  border-bottom: 4px solid #479DFD;
  padding: 18px 0;
  margin-left: 22px;
  cursor: pointer;
}

.navright .navcen {
  color: #3086FB;
  margin-right: 70px;
  font-weight: bold;
}

.navright .navsec {
  color: #3086FB;
  margin-right: 38px;
  font-weight: bold;
  cursor: pointer;
}
</style>
